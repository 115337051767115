import React from 'react'
import { Link } from 'gatsby'
import * as styles from './release-note-preview.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StructuredText } from 'react-datocms'

type RenderBodyProps = {
  body: any
}

const RenderBody = (props: RenderBodyProps) => {
  const { body } = props
  return body.map((item: any, i: number) => {
    if (item?.text?.value) {
      return <StructuredText data={item.text.value} key={i}></StructuredText>
    } else if (item?.youtubeUrl?.url) {
      return (
        <figure key={i}>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${item.youtubeUrl.providerUid}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <figcaption>{item.youtubeUrl.title}</figcaption>
        </figure>
      )
    } else if (item?.image) {
      return (
        <figure key={i}>
          <GatsbyImage
            image={item.image.gatsbyImageData}
            alt={item.image.alt}
          />
          <figcaption>{item.image.title}</figcaption>
        </figure>
      )
    } else if (item?.url) {
      return (
        <iframe
          key={i}
          width="900"
          height="600"
          src={`${item.url}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      )
    } else {
      console.error('unknown item', item)
    }
  })
}

export default RenderBody
