import React from 'react'
import { graphql, Link } from 'gatsby'
// import MoreStories from '../../components/more-stories'
import { StructuredText } from 'react-datocms'
import Seo from '../../components/molecules/seo'
import Layout from '../../components/molecules/layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import RenderBody from '../../components/atoms/render-body'

type PostProps = {
  data: any
  location: any
}
export default function Post({ data }: PostProps) {
  const {
    title,
    body,
    meta: { createdAt, fromNow },
  } = data.datoCmsBlogPost
  return (
    <Layout>
      <Seo title={title} />
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li key="blog">
            <Link to={`/blog`}>blog</Link>
          </li>
          <li className="is-active" key="current-page">
            <a href="#current-page" aria-current="page">
              {title}
            </a>
          </li>
        </ul>
      </nav>
      <section className="hero is-primary" key="hero">
        <div className="hero-body">
          <h1 className="page-title">{title}</h1>
          <time className="page-date" dateTime={createdAt}>
            {fromNow}
          </time>
        </div>
      </section>
      <section className="section" key="main">
        <div className="container is-widescreen">
          <div className="columns">
            <div className="column is-full">
              <main className="content blog-content">
                <article>
                  <RenderBody body={body} />
                </article>
              </main>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostsBySlug($slug: String) {
    datoCmsBlogPost(slug: { eq: $slug }) {
      body {
        ... on DatoCmsStructuredText {
          id
          text {
            value
          }
        }
        ... on DatoCmsYoutubeVideo {
          id
          youtubeUrl {
            url
            providerUid
            provider
            title
          }
        }
        ... on DatoCmsImageWithCaption {
          id
          image {
            gatsbyImageData
            title
            alt
          }
        }
        ... on DatoCmsIframeEmbed {
          id
          url
        }
      }
      title
      meta {
        createdAt(locale: "en")
        fromNow: createdAt(fromNow: true)
      }
    }
  }
`
